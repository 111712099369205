

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.login-form {
  max-width: 300px;
  margin: auto;
}

.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.survey-form-button {
  width: 30%;
  right: -200px;
}
.social {
  font-size:30px;
  border: 0;
  }

.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all .3s;
  }
.dynamic-delete-button:hover {
    color: #777;
  }

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  #components-back-top-demo-custom .ant-back-top {
    bottom: 100px;
  }

  #components-back-top-demo-custom .ant-back-top-inner {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #1088e9;
    color: #fff;
    text-align: center;
    font-size: 20px;
  } 

  /* * {
    outline: 1px solid red;
   } */

    .clickable:hover {
    cursor:pointer;
   } 

 /* 
   .ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 60px;
    background: #b1b9c9;
    background: #fff; 
    overflow: hidden;
  } */

  /* .imagereview .slick-dots button:hover{
    background-color: red;
    height: 20px;
    width: 50px;
  } 

  .ant-carousel .slick-dots button.slick-active:hover{
    background-color: green;
    height: 20px;
    width: 50px;
  } 
  
  .ant-carousel .slick-dots button.slick-active{
    background-color: yellow;
    height: 20px;
    width: 50px;
  }  */
  /* .ant-carousel .slick-slide h3 {
    color: #fff; 
  } */
